document.addEventListener("DOMContentLoaded", function () {

  console.log(`
  
  _                  _                            _             
  /\_/\___  ___| |_    ___  _ __  | |__   ___   _   _  ___  ___| |_ ___ _ __  
  \_ _/ _ \/ _ \ __|  / _ \| '__| | '_ \ / _ \ | | | |/ _ \/ _ \ __/ _ \ '_ \ 
   / \  __/  __/ |_  | (_) | |    | |_) |  __/ | |_| |  __/  __/ ||  __/ | | |
   \_/\___|\___|\__|  \___/|_|    |_.__/ \___|  \__, |\___|\___|\__\___|_| |_|
                                                |___/                         
  
  `)


  var hamburger = document.querySelector(".hamburger");
  var header = document.querySelector(".header")

  window.addEventListener("scroll", function () {
    if (window.scrollY != 0) {
      header.classList.add("is-scrolling")
    } else {
      header.classList.remove("is-scrolling")
    }
  })
});